import { createContext, useContext } from 'react';

import { LocaleID, PlatformID, SubjectID } from '@common/clients/api';
import { ContextData, ResolvedContextData } from '@common/defaults';
import { SupertokensStatus } from '@common/supertokens/SupertokensStatus';

export const NextContext = createContext<ContextData>({
    allowAds: true,
    allowBetting: true,
    allowTracking: true,
    contexts: [],
    env: {},
    hostname: '',
    isPlatformContext: false,
    magnoliaSessionID: '',
    userAgent: '',
    userID: 0,
    userSessionID: '',
    context: {
        id: 0,
        locale: LocaleID.EN,
        platform: PlatformID.VP,
        slug: '',
        subject: SubjectID.FOOTBALL,
        url: '',
        name: '',
        nameSimple: '',
        twitter: '',
        facebook: '',
        instagram: '',
        tiktok: '',
        youtube: '',
        address: '',
    },
    platform: {
        id: PlatformID.VP,
        name: '',
        subject: SubjectID.FOOTBALL,
        jwplayerKey: '',
    },
    config: {
        api: {
            host: '',
            timeout: 0,
        },
        socket: {
            host: '',
            timeout: 0,
        },
        sportmonksF1: {
            host: '',
            timeout: 0,
        },
        crossPlatform: {},
        captchaConfig: {
            siteKey: '',
        },
    },
    supertokens: {
        status: SupertokensStatus.UNKNOWN,
        sAccessToken: '',
        sRefreshToken: '',
        sFrontToken: '',
        sAntiCsrf: '',
        'st-last-access-token-update': '',
    },
});

export const useContextData = (): ResolvedContextData => {
    const contextData = useContext(NextContext);
    return contextData as ResolvedContextData;
};
