'use client';

import { FC, PropsWithChildren, useEffect, useState } from 'react';
import JsCookies from 'js-cookie';
import { SuperTokensWrapper as Wrapper } from 'supertokens-auth-react';
import Session from 'supertokens-auth-react/recipe/session';

import { logger } from '@common/logger';
import { initSupertokens } from '@common/supertokens/initSupertokens';
import { getIsEnabled } from '@common/supertokens/isEnabled';
import { SupertokensStatus } from '@common/supertokens/SupertokensStatus';
import { Duration } from '@common/types';
import { useContextData } from '@common/useContextData';

interface Props extends PropsWithChildren {
    autoRefresh?: boolean;
}

function getAutoRefreshMargin(env: Record<string, string | undefined>): number {
    const autoRefreshMarginEnv = env.NEXT_PUBLIC_SUPERTOKENS_AUTO_REFRESH_MARGIN;
    return autoRefreshMarginEnv ? +autoRefreshMarginEnv : 5 * Duration.minute;
}

function getAutoRefreshDelay(env: Record<string, string | undefined>): number | undefined {
    const sFrontToken = JsCookies.get('sFrontToken');
    if (!sFrontToken) {
        logger.warn('[getAutoRefreshDelay] Could not get sFrontToken cookie.');
        return;
    }
    const frontToken = JSON.parse(Buffer.from(sFrontToken, 'base64').toString('utf8'));
    const exp = frontToken.up.exp;
    if (!exp) {
        logger.warn("[getAutoRefreshDelay] Could not get 'up.exp' from sFrontToken cookie.");
        return;
    }
    const expMs = exp * 1000;
    const now = Date.now();
    const autoRefreshMargin = getAutoRefreshMargin(env);
    return expMs - now - autoRefreshMargin;
}

const CustomWrapper: FC<Props> = ({ children, autoRefresh = false }) => {
    const contextData = useContextData();

    // See https://supertokens.com/docs/thirdpartyemailpassword/nextjs/session-verification/in-ssr#2-doing-manual-refresh-on-the-frontend
    const [needsToRefresh, setNeedsToRefresh] = useState<boolean>(
        !!contextData && contextData.supertokens?.status === SupertokensStatus.NEEDS_REFRESH,
    );

    initSupertokens(contextData);

    useEffect(() => {
        async function doRefresh() {
            if (await Session.attemptRefreshingSession()) {
                logger.debug('Successfully refreshed access token.');
                // After session refreshing, we reload the page.
                // This will send the new access token to the server, and then getServerSideProps will succeed.
                location.reload();
            } else {
                logger.debug('Failed to refresh access token.');
                setNeedsToRefresh(false);
            }
        }
        if (needsToRefresh) doRefresh();
    }, [needsToRefresh]);

    useEffect(() => {
        if (!autoRefresh) {
            return;
        }

        let timeoutID: NodeJS.Timeout | undefined;

        const doAutoRefresh = async (attemptRefresh: boolean): Promise<void> => {
            if (attemptRefresh) {
                if (!(await Session.attemptRefreshingSession())) {
                    return;
                }
            }

            const delay = getAutoRefreshDelay(contextData.env);
            if (delay === undefined) {
                return;
            }
            timeoutID = setTimeout(() => doAutoRefresh(true), delay);
        };
        doAutoRefresh(false);

        return () => clearTimeout(timeoutID);
    }, [autoRefresh]);

    if (needsToRefresh) {
        // in case the frontend needs to refresh, we show nothing.
        // Alternatively, you can show a spinner.
        return null;
    }

    return <Wrapper>{children}</Wrapper>;
};

const NoWrapper: FC<Props> = ({ children }) => {
    return children;
};

export const SupertokensWrapper: FC<Props> = (props) => {
    const { env } = useContextData();
    return getIsEnabled(env) ? <CustomWrapper {...props} /> : <NoWrapper {...props} />;
};
